import {
  ChipField,
  Create,
  DatagridConfigurable,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  Labeled,
  List,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  ArrayInput,
  SimpleFormIterator, ReferenceArrayInput, AutocompleteArrayInput, useResourceContext, useRecordContext, useGetRecordId
} from "react-admin"
import React, { useEffect, useState } from "react"
import moment from 'moment'
import { AppContext, useAppContext } from '../modules/app-context'
import { parseResource } from "~/modules/parse-resource"

export const EntitiesTemplates = () => {
  return (
    <List>
      <DatagridConfigurable rowClick={'edit'}>
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Описание" source="description" />
        <TextField label="Символьный код" source="symbolCode" />
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="ID родителя" source="parentId" />
        <FunctionField
          source="createdAt"
          label="Дата создания"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          source="updatedAt"
          label="Дата обновления"
          render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
        />
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

export const EntitiesTemplateEdit = () => {
  const { applicationId } = useAppContext()
  const recordId = useGetRecordId()

  if (!recordId) {
    return null
  }

  const optionRenderer = (choice: any) => `${choice.id}_${choice.name}`

  return (
    <Edit>
      <SimpleForm>
        <Labeled label="ID">
          <TextInput label="" source="id" readOnly />
        </Labeled>
        <Labeled label="Название">
          <TextInput label="" source="name" />
        </Labeled>
        <Labeled label="Описание">
          <TextInput label="" source="description" />
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput label="" source="symbolCode" />
        </Labeled>
        <Labeled label="Родитель">
          <ReferenceInput source="parentId" reference="entities-templates">
            <SelectInput
              label=""
              source="entities-templates"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        {applicationId ? (
          <Labeled label="Приложение">
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений">
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
              />
            </ReferenceInput>
          </Labeled>
        )}
        <Labeled label="Связи">
          <ArrayInput label="" source="relationsInfo">
            <SimpleFormIterator inline>
              <ReferenceInput
                source="relatedTemplateId"
                reference="entities-templates"
              >
                <SelectInput
                  label=""
                  source="entities-templates"
                  optionValue="id"
                  optionText="name"
                />
              </ReferenceInput>
              <SelectInput
                label=""
                source="typeId"
                choices={[]}
                optionValue="id"
                optionText="name"
              />
              <SelectInput
                label=""
                source="onDeleteActionIds"
                choices={[]}
                optionValue="id"
                optionText="name"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Роли">
          <ArrayInput label="" source="rolesInfo">
            <SimpleFormIterator inline>
              <ReferenceInput source="roleId" reference="roles">
                <SelectInput
                  label=""
                  source="roles"
                  optionValue="id"
                  optionText="name"
                />
              </ReferenceInput>
              <SelectInput
                label=""
                source="typeId"
                choices={[]}
                optionValue="id"
                optionText="name"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Свойства">
          <ReferenceArrayInput
            source="propertiesId"
            reference="entities-properties"
          >
            <SelectArrayInput
              readOnly
              label="Выберите свойста"
              source="propertiesId"
              optionText={'name'}
              optionValue={'id'}
            />
          </ReferenceArrayInput>
        </Labeled>
        <Labeled label="Дата создания">
          <FunctionField
            source="createdAt"
            render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
          />
        </Labeled>
        <Labeled label="Дата обновления">
          <FunctionField
            source="createdAt"
            render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
          />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

export const EntitiesTemplateCreate = () => {
  const { applicationId } = useAppContext()

  return (
    <Create>
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Название">
          <TextInput label="" source="name" />
        </Labeled>
        <Labeled label="Описание">
          <TextInput label="" source="description" />
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput label="" source="symbolCode" />
        </Labeled>
        <Labeled label="Родитель">
          <ReferenceInput source="parentId" reference="entities-templates">
            <SelectInput
              label=""
              source="entities-templates"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Связи">
          <ArrayInput label="" source="relationsInfo">
            <SimpleFormIterator inline>
              <ReferenceInput
                source="relatedTemplateId"
                reference="entities-templates"
              >
                <SelectInput
                  label=""
                  source="entities-templates"
                  optionValue="id"
                  optionText="name"
                />
              </ReferenceInput>
              <SelectInput
                label=""
                source="typeId"
                choices={[]}
                optionValue="id"
                optionText="name"
              />
              <SelectInput
                label=""
                source="onDeleteActionIds"
                choices={[]}
                optionValue="id"
                optionText="name"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Роли">
          <ArrayInput label="" source="rolesInfo">
            <SimpleFormIterator inline>
              <ReferenceInput source="roleId" reference="roles">
                <SelectInput
                  label=""
                  source="roles"
                  optionValue="id"
                  optionText="name"
                />
              </ReferenceInput>
              <SelectInput
                label=""
                source="typeId"
                choices={[]}
                optionValue="id"
                optionText="name"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Список приложений">
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label=""
              source="applications"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}
