import {
  Create,
  DatagridConfigurable,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  List,
  SimpleForm,
  TextField,
  ReferenceField,
  BulkDeleteWithConfirmButton,
  Pagination,
  FilterForm,
  SelectColumnsButton,
  FilterButton,
  TextInput,
  DateInput,
  Labeled,
  SelectInput,
  ReferenceInput,
  ChipField,
  required,
  useNotify,
  useRedirect,
  TopToolbar,
  SaveButton, ReferenceArrayInput, AutocompleteArrayInput,
} from "react-admin"
import moment from 'moment'
import React from 'react'
import { Stack } from '@mui/material'
import { AppContext, useAppContext } from '../modules/app-context'
import { useFormContext } from 'react-hook-form'

export const EntitiesProperties = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable
        bulkActionButtons={<PropertyButtons />}
        rowClick="edit"
        size="medium"
      >
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Описание" source="description" />
        <TextField label="Символьный код" source="symbolCode" />
        <ReferenceField
          source="typeId"
          reference="entities-properties-types"
          label="Тип"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="createdAt"
          label="Дата создания"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          source="updatedAt"
          label="Дата обновления"
          render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
        />
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

export const EntitiesPropertiesEdit = () => {
  const { applicationId } = useAppContext()
  return (
    <Edit>
      <SimpleForm>
        <Labeled label="Id">
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Название">
          <TextInput source="name" />
        </Labeled>
        <Labeled label="Описание">
          <TextInput source="description" />
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput source="symbolCode" />
        </Labeled>
        <Labeled label="Тип">
          <ReferenceInput source="typeId" reference="entities-properties-types">
            <SelectInput
              label=""
              source="entities-properties-types"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        {applicationId ? (
          <Labeled label="Приложение" sx={{ fontSize: 21 }}>
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений*" sx={{ fontSize: 21 }}>
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={required()}
              />
            </ReferenceInput>
          </Labeled>
        )}
        {/*<Labeled label={"Sort"} >*/}
        {/*  <NumberInput source="templatesRelations.sort" />*/}
        {/*</Labeled>*/}
        <Labeled label="Шаблоны">
          <ReferenceArrayInput source="templatesRelations.templateId" reference="entities-templates">
            <AutocompleteArrayInput
              label="Выберите шаблоны"
              source={"entities-templates"}
              optionText="name"
              optionValue={'id'}
              filterToQuery={searchText => ({name: searchText})}
            />
          </ReferenceArrayInput>
        </Labeled>
        <Labeled label="Дата создания" sx={{ fontSize: 21 }}>
          <DateInput label="" source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления" sx={{ fontSize: 21 }}>
          <DateInput label="" source="updatedAt" readOnly />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

export const EntitiesPropertiesCreate = () => {
  const { applicationId } = useAppContext()
  return (
    <Create>
      <SimpleForm
        toolbar={<CreateActions />}
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Название" sx={{ fontSize: 21 }}>
          <TextInput label="" source="name" />
        </Labeled>
        <Labeled label="Описание">
          <TextInput source="description" />
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput source="symbolCode" />
        </Labeled>
        <Labeled label="Тип">
          <ReferenceInput source="typeId" reference="entities-properties-types">
            <SelectInput
              label=""
              source="entities-properties-types"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Список приложений" sx={{ fontSize: 21 }}>
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label=""
              source="applications"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        {/*<Labeled label={"Sort"} >*/}
        {/*  <NumberInput source="templatesRelations.sort" />*/}
        {/*</Labeled>*/}
        <Labeled label="Шаблоны">
          <ReferenceArrayInput source="templatesRelations.templateId" reference="entities-templates">
            <AutocompleteArrayInput
              label="Выберите шаблоны"
              source={"entities-templates"}
              optionText="name"
              optionValue={'id'}
              filterToQuery={searchText => ({name: searchText})}
            />
          </ReferenceArrayInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

const PropertyButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Название" source="name" defaultValue="" />,
  <TextInput label="Описание" source="description" defaultValue="" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]

export const CreateActions = () => {
  const notify = useNotify()
  const { reset } = useFormContext()
  const redirect = useRedirect()

  return (
    <TopToolbar>
      <SaveButton
        label="Save"
        mutationOptions={{
          onSuccess: () => {
            notify('Запись создана')
            reset()
            redirect('/entities-properties')
          },
        }}
        type="button"
        variant="text"
      />
    </TopToolbar>
  )
}
